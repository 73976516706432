import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Amplify } from "aws-amplify";
import config from "@src/config";
import { Provider } from "react-redux";
import { store } from "./redux/storeConfig/store";
import { ToastContainer } from "react-toastify";
import { ThemeContext } from "./utility/context/ThemeColors";
import Spinner from "./@core/components/spinner/Fallback-spinner";
import "./@core/components/ripple-button";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@styles/react/libs/toastify/toastify.scss";
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";
import { CookiesProvider } from "react-cookie";
import { hotjar } from "react-hotjar";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { BrowserRouter } from "react-router-dom";
import mixpanel from "mixpanel-browser";

Sentry.init({
  dsn: config.sentryDsn,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const LazyApp = lazy(() => import("./App"));

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: config.apiGateway.API_NAME,
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
  Storage: {
    AWSS3: {
      bucket: config.storage.BUCKET,
      region: config.storage.REGION,
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
      level: config.storage.level,
    },
  },
});

// Will init Mixpanel if the REACT_APP_MIXPANEL_TOKEN is set on Netlify env variables
// Documentation for Mixpanel: https://developer.mixpanel.com/docs/javascript-quickstart
if (config.mixpanelToken) {
  mixpanel.init(config.mixpanelToken, { debug: !config.isProd });
}

// Will init Hotjar if the REACT_APP_HOTJAR_ID is set on Netlify env variables
// The user must be identified and events can then be tracked, according to the documentation: https://www.npmjs.com/package/react-hotjar
if (config.hotjarId) {
  hotjar.initialize(config.hotjarId, 6);
}

const { Paddle } = window as any;
if (!config.isProd) {
  Paddle.Environment.set("sandbox");
}
Paddle.Setup({
  vendor: config.isProd ? 127372 : 3164,
  eventCallback: (data: any) => {
    if (data.event === "Checkout.PaymentComplete") {
      mixpanel.track("Client");
      //@ts-ignore
      window.dataLayer.push("Client");
    }
  },
});

ReactDOM.render(
  <CookiesProvider>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <ThemeContext>
          <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
            <LazyApp />
            <ToastContainer newestOnTop />
          </BrowserRouter>
        </ThemeContext>
      </Suspense>
    </Provider>
  </CookiesProvider>,
  document.getElementById("root")
);
