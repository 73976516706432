const isProd = process.env.REACT_APP_STAGE == "prod";
const REGION = "us-east-1";

const config = {
  isProd,
  contactEmail: "contact@oceanproxy.com",
  apiGateway: {
    REGION,
    URL: `https://${
      isProd ? "3cveht8xol" : "hinaedciqk"
    }.execute-api.us-east-1.amazonaws.com/${isProd ? "prod" : "dev"}`,
    API_NAME: isProd ? "oceanproxy-api-prod" : "oceanproxy-api-dev",
  },
  cognito: {
    REGION,
    USER_POOL_ID: isProd ? "us-east-1_mfLa43VvY" : "us-east-1_RrwHLaWbp",
    APP_CLIENT_ID: isProd
      ? "2ltvvsba7ni6aalk0bv1udj5gb"
      : "h0n9v3lq4v55b63udqk5df030",
    IDENTITY_POOL_ID: isProd
      ? "us-east-1:98474437-860b-4002-a931-a23bd0595a79"
      : "us-east-1:98b7c2e6-5202-4e51-96ba-b134376cad39",
  },
  storage: {
    REGION,
    BUCKET: "oceanproxy-referrals-bills",
    level: "protected",
  },
  loginTitle: "Oceanproxy - Se connecter",
  registerTitle: "Oceanproxy - S'inscrire gratuitement",
  dashboardTitle: "Dashboard - Oceanproxy",
  landingUrl: "https://oceanproxy.com",
  affiliateLinkPrefix: isProd
    ? "https://app.oceanproxy.com/referral/"
    : "http://localhost:3000/referral/",
  mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN,
  hotjarId: Number(process.env.REACT_APP_HOTJAR_ID),
  sentryDsn:
    "https://bb986aa45eb648c68fbf655088e53d10@o1206514.ingest.sentry.io/6340114",
};

export default config;
